import React from "react"

const DetailsButton = props => {
  return (
    <button
      className="detailsBtn"
      onClick={() => props.data.navigate(props.redirectUrl)}
    >
      Details
    </button>
  )
}

export default DetailsButton
