import React from "react"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import DetailsButton from "../components/detailsButton"
import { seoKeyWords } from "../helpers/seo"

class IndexPost extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <div className="row product-main">
          {data.data.allPrintfulProduct.edges.map(product => {
            return (
              /*eslint-disable */
              <div
                className="Catalogue__item col-sm-12 col-md-6 col-lg-4"
                key={product.node.productId}
                onClick={() =>
                  data.navigate(`/${product.node.name.replace(/\s/g, "")}`)
                }
                onKeyDown={() => null}
                role="link"
              >
                {/* eslint-enable */}
                <div className="details_List">
                  {!product.node.thumbnail_url ? (
                    <div className="no-image">No Image</div>
                  ) : (
                    <img src={product.node.thumbnail_url} alt="thumbnail" />
                  )}

                  <div className="details_inner">
                    <h2>
                      <Link to={`/${product.node.name.replace(/\s/g, "")}`}>
                        {product.node.name}
                      </Link>
                    </h2>
                    <div className="row">
                      <div className="col-sm-4 align-self-center">
                        <span className="price">${19}</span>
                      </div>
                      <div className="col-sm-8 text-right align-self-center">
                        <DetailsButton
                          data={data}
                          redirectUrl={`/${product.node.name.replace(
                            /\s/g,
                            ""
                          )}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

const IndexPage = data => (
  <Layout>
    <SEO title="Coding Shirts" keywords={seoKeyWords} />
    <Banner BannerData={data.data.allContentfulHeaderBanner.edges} />
    <div className="container">
      <div className="text-center">
        <h2 className="with-underline">Latest Items</h2>
      </div>
      <IndexPost data={data}></IndexPost>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query AboutQuery {
    allPrintfulProduct {
      edges {
        node {
          id
          productId
          externalId
          name
          variants
          synced
          thumbnail_url
          colors
          sizes
          sync_variants {
            id
            external_id
            currency
            files {
              created
              dpi
              filename
              hash
              height
              id
              mime_type
              preview_url
              size
              status
              thumbnail_url
              type
              visible
              width
            }
            name
            options {
              id
            }
            product {
              variant_id
              product_id
              image
              name
            }
            retail_price
            sync_product_id
            synced
            variant_id
          }
        }
      }
    }
    allContentfulProduct(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          name
          slug
          rating
          image {
            fluid(maxWidth: 1000) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          price
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    }
    allContentfulHeaderBanner {
      edges {
        node {
          title
          subHeading
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    contentfulDealCountDown {
      title
      featureImage {
        fluid(maxWidth: 1800) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      date(formatString: "D MMMM, YYYY")
    }
    allContentfulBlogs(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          featureImage {
            fluid(maxWidth: 1120) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`
